export const createSenderAddress = /* GraphQL */ `
  mutation CreateSenderAddress($input: CreateSenderAddressInput!) {
    createSenderAddress(input: $input) {
      id
      createdAt
      updatedAt
      formOfAddress
      firstName
      lastName
      isProfessional
      companyName
      addressPrefix
      address
      zipCode
      city
      email
      phoneNumber
      instructions
      phoneNumberLandline
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
        }
        idDocument {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        kbis {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          nextToken
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          nextToken
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            mailmodes
            owner
          }
          nextToken
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          nextToken
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        defaultSignature {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        version
        owner
      }
      owner
    }
  }
`
export const updateSenderAddress = /* GraphQL */ `
  mutation UpdateSenderAddress($input: UpdateSenderAddressInput!) {
    updateSenderAddress(input: $input) {
      id
      createdAt
      updatedAt
      formOfAddress
      firstName
      lastName
      isProfessional
      companyName
      addressPrefix
      address
      zipCode
      city
      email
      phoneNumber
      instructions
      phoneNumberLandline
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
        }
        idDocument {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        kbis {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          nextToken
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          nextToken
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            mailmodes
            owner
          }
          nextToken
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          nextToken
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        defaultSignature {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        version
        owner
      }
      owner
    }
  }
`
export const deleteSenderAddress = /* GraphQL */ `
  mutation DeleteSenderAddress($input: DeleteSenderAddressInput!) {
    deleteSenderAddress(input: $input) {
      id
      createdAt
      updatedAt
      formOfAddress
      firstName
      lastName
      isProfessional
      companyName
      addressPrefix
      address
      zipCode
      city
      email
      phoneNumber
      instructions
      phoneNumberLandline
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
        }
        idDocument {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        kbis {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          nextToken
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          nextToken
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            mailmodes
            owner
          }
          nextToken
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          nextToken
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        defaultSignature {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        version
        owner
      }
      owner
    }
  }
`
