import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import intervalPlural from "i18next-intervalplural-postprocessor"

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    // we init with resources
    resources: {
      fr: {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Welcome to React": "Welcome to React and react-i18next",
          welcome: "Hello <br/> <strong>World</strong>",
          freeLetterSuffix: "- {{count}} lettre gratuite",
          freeLetterSuffix_plural: "- {{count}} lettres gratuites",
          pageCount: "{{count}} page",
          pageCount_plural: "{{count}} pages",
          updateSenderInformationInAccount: "Mettre à jour mon adresse par défaut",
          updateCurrentSenderAddress: "Enregistrer la modification de cette adresse",
          addSenderInformationInAccount: "Enregistrer une nouvelle adresse",
          basketPreparation: "Préparation de votre commande",
          basketValidation: "Validation de votre commande",
          accountRefresh: "Rafraîchissement du compte",
          sign_add_new_signature_widget: "Ajouter une signature",
          save_and_continue: "Enregistrer les modifications",
          cancel: "Annuler",
          sign_popover_title: "Nouvelle signature",
          sign_popover_content: "Signez ici",
          sign_popover_delete: "Annuler",
          sign_confirmation_empty_widgets_title: "Signatures non renseignées",
          sign_confirmation_empty_widgets_content:
            "Un ou plusieurs élements de signature sont vides. Êtes-vous sûrs de vouloir enregistrer le document ?",
          sign_confirmation_confirmation_save_as_is: "Supprimer les signatures non renseignées et enregistrer",
          sign_confirmation_continue_editing: "Poursuivre l'édition des signatures",
          sign_cancellation_confirmation_title: "Signatures renseignées",
          sign_cancellation_confirmation_content:
            "Une ou plusieurs signatures ont été ajoutées au document mais non sauvegardées. Etes vous sur de vouloir quitter l'édition des signatures?",
          sign_cancellation_confirmation_quit_and_delete: "Quitter l'edition et annuler les signatures en cours",
          sign_form_draw_mouse: "Dessinez la signature",
          sign_form_import_image: "Ou sélectionnez une image (.png)",
          attachments_delete_attachment: "Supprimer le document",
          attachments_edit_attachment: "Signer le document",
          lre_missing_info_warning:
            "Votre courrier peut être envoyé en Lettre Recommandé Électronique (juridiquement équivalent au papier) si vous disposez des informations suivantes :",
          lre_personal_consent_label:
            "Accord de mon destinataire pour lui envoyer des courriers en mode électronique sur l'email renseigné*",
          lre_personal_consent_desc:
            "*Votre destinataire étant un particulier, il vous faut recueillir son accord au préalable.\nVoir notre ",
          mail_mode_size_warning:
            "Disponible uniquement pour des fichiers d'un total de {{maxPaperTotalSizeMegs}}Mo maximum ({{maxPaperSingleFileMegs}}Mo max par fichier)",
          mail_mode_pages_warning: "Disponible uniquement pour des envois de {{maxPages}} pages maximum",
          recommended_mail_mode_alert: "Mode d'envoi conseillé : légal, plus rapide, moins cher & sans papier",
          mail_mode_eco_desc:
            "Votre courrier envoyé en lettre éco : plus de déplacement à La Poste, nous nous chargeons de tout (pour 0,08€HT par page imprimée recto/verso).",
          free_letter_amount: "Offert",
          price: "{{amount}} €",
          unavailable_mail_mode: "Non disponible",
          mail_mode_with_ack: "avec accusé de réception {{ackPrice}}",
          mail_mode_lre_email_form: "EMAIL DU DESTINATAIRE",
          mail_mode_lre_phone_form: "TELEPHONE PORTABLE DU DESTINATAIRE",
          attachment_bigger_than_max_paper_file_size:
            'Votre courrier pourra être envoyé en recommandé électronique uniquement (maximum {{maxPaperSingleFileMegs}}Mo par fichier et {{maxPaperTotalSizeMegs}}Mo au total pour les courriers "papier")',
          attachment_bigger_than_max_paper_page_count:
            'Votre courrier pourra être envoyé en recommandé électronique uniquement ({{maxLRPages}} pages maximum pour les recommandés "papier" et les lettres suivies, {{maxEcoPages}} pages maximum pour les lettres éco)',
          attachment_bigger_than_max_eco_pages:
            'Votre courrier pourra être envoyé en recommandé "papier" ou électronique, ou en lettre suivie uniquement ({{maxEcoPages}} pages maximum pour les lettres éco)',
        },
      },
    },
    fallbackLng: "fr",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    lng: "fr-FR",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
