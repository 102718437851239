import React, { useContext, useEffect, useMemo, useState } from "react"
import { AccountContext } from "./AccountComponent"
import {
  apiComputeBasketPrice,
  apiCreateNewBasket,
  apiFormTokenBasket,
  apiListLastBaskets,
  apiPromoCode,
  apiSubscribeBasketUpdate,
  apiSubscribeCreateBasket,
  apiUpdateBasket,
} from "./BasketApi"

export const BasketContext = React.createContext(null)

export const stateLessGetFirstOrCreate = async (accountId, setLastBaskets, setCurrentBasket, refreshBasket) => {
  const basketsData = await apiListLastBaskets(accountId)
  setLastBaskets(basketsData)
  const basket = basketsData.find((o) => o.status === "draft")
  if (basket) {
    setCurrentBasket(basket)
    return basket
  } else {
    await apiCreateNewBasket(accountId)
    const createdBasket = await refreshBasket()
    if (!createdBasket) {
      throw "basket creation is not working..."
    }
    return createdBasket
  }
}
const stateLessRefreshBasket = async (setCurrentBasket, setLastBaskets) => {
  const baskets = await apiListLastBaskets()
  const refreshedCurrentBasket = baskets.find((b) => b.status === "draft")
  setCurrentBasket(refreshedCurrentBasket)
  setLastBaskets(baskets)
  return refreshedCurrentBasket
}

//We could remove the dependency if we manage to subscribe to this information?
export const BasketProvider = ({ children }) => {
  const { account, refreshAccount } = useContext(AccountContext)
  const [currentBasket, setCurrentBasket] = useState(null)
  const [lastBaskets, setLastBaskets] = useState(null)
  const [basketPriceDetails, setBasketPriceDetails] = useState(null)
  const accountId = useMemo(() => (account ? account.id : null), [account])
  const freeLetters = useMemo(() => (account ? account.freeLetters || 0 : null), [account])
  const prepaidCodes = useMemo(() => (account ? account.prepaidCodes || [] : null), [account])
  const lettersInBasket = useMemo(
    () =>
      currentBasket
        ? currentBasket.letters
          ? currentBasket.letters.items
            ? currentBasket.letters.items
            : null
          : null
        : null,
    [currentBasket]
  )
  const refreshBasket = useMemo(
    () => () => stateLessRefreshBasket(setCurrentBasket, setLastBaskets, accountId),
    [setCurrentBasket, setLastBaskets, accountId]
  )

  const getFirstOrCreate = useMemo(
    () => () => stateLessGetFirstOrCreate(accountId, setLastBaskets, setCurrentBasket, refreshBasket),
    [accountId, setLastBaskets, setCurrentBasket, refreshBasket]
  )

  useEffect(() => {
    if (accountId) {
      const onNext = async (basket) => {
        const baskets = await apiListLastBaskets(accountId)
        const newBasketId = basket.id
        const basketInList = baskets.find((b) => b.id === newBasketId)
        if (!basketInList) {
          baskets.push(basket)
        }
        setLastBaskets(baskets)
        if (basket.status === "draft") {
          setCurrentBasket(basket)
        }
      }
      const subscriptionCreateBasket = apiSubscribeCreateBasket(accountId, onNext)
      getFirstOrCreate()
        .then()
        .catch((e) => {
          console.error("Error while initiliazing currentBasket")
          console.error(e)
        })
      return () => {
        subscriptionCreateBasket.unsubscribe()
      }
    } else {
      setLastBaskets(null)
    }
  }, [getFirstOrCreate, accountId])

  useEffect(() => {
    if (accountId) {
      const onNext = (basket) => {
        if (basket.status === "draft") {
          setCurrentBasket(basket)
        }
      }
      const subscriptionUpdateBasket = apiSubscribeBasketUpdate(accountId, onNext)
      return () => {
        subscriptionUpdateBasket.unsubscribe()
      }
    }
  }, [accountId])

  useEffect(() => {
    if (lettersInBasket) {
      apiComputeBasketPrice(freeLetters, prepaidCodes, lettersInBasket)
        .then((details) => setBasketPriceDetails(details))
        .catch((e) => {
          console.error("Error while computing basket price")
          console.error(e)
        })
    }
  }, [lettersInBasket, freeLetters, prepaidCodes])

  const setBasketStatusToSend = async () => {
    const now = new Date()
    await updateCurrentBasket({ status: "to_send", paymentDate: now.toISOString() })
    await apiCreateNewBasket(accountId)
    await refreshBasket()
  }

  const updateCurrentBasket = async (input) => {
    const basket = currentBasket
    await apiUpdateBasket(input, basket)
  }

  const checkPromoCode = async (promoCode) => {
    const message = await apiPromoCode(promoCode)
    await refreshAccount()
    return message
  }

  return (
    <BasketContext.Provider
      value={{
        basketInfos: { currentBasket, lastBaskets },
        letterInfos: { ...basketPriceDetails },
        lettersInBasket,
        checkPromoCode,
        setBasketStatusToSend,
        refreshBasket,
        getFirstOrCreate,
        apiFormTokenBasket,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}

export const BasketConsumer = BasketContext.Consumer

export const withBasket = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <BasketConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</BasketConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}
