import React, { createContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const CanonicalLinkContext = createContext({})
export const CanonicalLinkProvider = ({ children }) => {
  const { pathname } = useLocation()
  const [canonicalPath, setCanonicalPath] = useState(null)
  useEffect(() => {
    setCanonicalPath(pathname)
  }, [pathname])

  return <CanonicalLinkContext.Provider value={{ setCanonicalPath }}>{children}</CanonicalLinkContext.Provider>
}
