import React, { useContext, useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getLetterPrice } from "../graphql/queries"
import { AuthContext } from "./AuthComponent"
import createPersistedState from "use-persisted-state"
import { v4 as uuid } from "uuid"
import Storage from "@aws-amplify/storage"
import config from "../aws-exports"

const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config

const useGuestLetterId = createPersistedState("guestLetterId")

export const GuestLettersContext = React.createContext()

export const storeAttachments = async (filesWithPages, id) => {
  const storeInputs = filesWithPages.map((fwp) => {
    const file = fwp.file
    const extension = file.name.split(".")[1]
    const { type: mimeType } = file
    const attachmentUid = uuid()
    const key = `temporary/${id}/attachments/${attachmentUid}.${extension}`
    return { ...fwp, key, mimeType }
  })

  for (const storeInput of storeInputs) {
    const { file, key, mimeType } = storeInput
    await Storage.put(key, file, {
      level: "public",
      contentType: mimeType,
    })
  }
  return storeInputs.map((storeInput) => {
    const pages = storeInput.numPages
    const { file, key, mimeType } = storeInput
    return {
      format: mimeType,
      fileName: file.name,
      contentLength: file.size,
      pages: pages,
      content: {
        bucket,
        key,
        region,
      },
    }
  })
}

const temporarySave = async (id, file, folder = "attachments") => {
  const { type: mimeType } = file
  const key = `temporary/${id}/${folder ? `${folder}/` : ""}${file.name}`
  await Storage.put(key, file, {
    level: "public",
    contentType: file.type,
  })
  const storeInput = { file, key, mimeType }
  const pages = storeInput.numPages
  return {
    format: mimeType,
    fileName: file.name,
    contentLength: file.size,
    pages: pages,
    content: {
      bucket,
      key,
      region,
    },
  }
}

const readFromStorage = async (id, filename) => {
  const key = `temporary/${id}/${filename}`
  const url = await Storage.get(key, { level: "public" })
  const res = await fetch(url)
  if (res.ok) {
    const data = await res.json()
    if (data.template && !data.template.requiredDocuments) {
      data.template = null
    }

    if (data.attachments && !Object.prototype.toString.call(data.attachments) === "[object Array]") {
      data.attachments = []
    }
    return data
  } else {
    return null
  }
}

export const GuestLettersProvider = (props) => {
  const { registerWith } = useContext(AuthContext)
  const [guestLetterId, updateGuestLetterId] = useGuestLetterId(null)
  const [guestLetter, updateGuestLetterFromS3] = useState(null)
  const [isDirectSendPopinVisible, updateDirectSendPopinVisible] = useState(false)
  const [hasRegistered, updateHasRegistered] = useState(false)

  useEffect(() => {}, [guestLetter])
  useEffect(() => {
    const initializeLetter = async () => {
      if (guestLetterId != null) {
        const guestLetterFS = await readFromStorage(guestLetterId, "letter.json")
        updateGuestLetterFromS3(guestLetterFS)
      }
    }
    initializeLetter()
      .then(() => console.log("initializedLetter"))
      .catch((e) => console.log("error" + e))
  }, [guestLetterId])

  function ensureGuestLetterId() {
    if (guestLetterId == null) {
      const date = new Date()
      const anUuid = uuid()
      function pad(n, width, z) {
        z = z || "0"
        n = n + ""
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
      }
      const paddedDate = `${date.getFullYear()}${pad(date.getMonth() + 1, 2)}${pad(date.getDate(), 2)}`
      const paddedHours = pad(date.getHours(), 2)
      const paddedMinutes = pad(date.getMinutes(), 2)
      const paddedSeconds = pad(date.getSeconds(), 2)
      const timeStamp = `${paddedDate}-${paddedHours}${paddedMinutes}${paddedSeconds}`
      const letterUid = `${timeStamp}-${anUuid}`
      updateGuestLetterId(letterUid)
      return letterUid
    }
    return guestLetterId
  }

  const saveLetterUpdate = async (update, id = null) => {
    const letterId = id || ensureGuestLetterId()
    const letter = guestLetter || {}
    const updatedLetter = { id: letterId, ...letter, ...update }
    const content = JSON.stringify(updatedLetter)
    const blob = new Blob([content], { type: "application/json" })
    const file = new File([blob], `letter.json`, {
      type: "application/json",
      lastModified: new Date(),
    })
    await temporarySave(letterId, file, null)
    const letterFromStorage = await readFromStorage(letterId, "letter.json")
    updateGuestLetterFromS3(letterFromStorage)
  }

  const createGuestLetterFromTemplate = async (filesWithPages, aTemplate, aTemplateRecipient, letterElements) => {
    const hasFulfilledTemplateRequiredDocuments = false
    const id = ensureGuestLetterId()
    const storedAttachments = await storeAttachments(filesWithPages, id)

    const attachments = storedAttachments
    let template = {}
    if (aTemplate) {
      const tp = {
        templateId: aTemplate.id,
        templateName: aTemplate.name,
        requiredDocuments: aTemplate.mandatory_documents,
        hasValidatedRequiredDocuments:
          hasFulfilledTemplateRequiredDocuments || aTemplate.mandatory_documents.length <= 0,
      }
      if (aTemplateRecipient) {
        tp.recipientId = aTemplateRecipient.id
      }
      template = tp
    }
    await saveLetterUpdate({ ...letterElements, attachments, template }, id)
    return guestLetter
  }

  const createDraft = async (filesWithPages, aTemplate, hasFulfilledTemplateRequiredDocuments, aTemplateRecipient) => {
    const id = ensureGuestLetterId()
    const storedAttachments = await storeAttachments(filesWithPages, id)
    let attachments = (guestLetter && guestLetter.attachments) || null
    if (attachments) {
      attachments = [...attachments, ...storedAttachments]
    } else {
      attachments = storedAttachments
    }
    let template = {}
    if (aTemplate) {
      const tp = {
        templateId: aTemplate.id,
        templateName: aTemplate.name,
        requiredDocuments: aTemplate.mandatory_documents,
        hasValidatedRequiredDocuments:
          hasFulfilledTemplateRequiredDocuments || aTemplate.mandatory_documents.length <= 0,
      }
      if (aTemplateRecipient) {
        tp.recipientId = aTemplateRecipient.id
      }
      template = tp
    }
    await saveLetterUpdate({ attachments, template }, id)
    return guestLetter
  }

  const editRecipient = async (letter) => {
    await saveLetterUpdate({ recipient: letter.recipient })
    return guestLetter
  }

  const editMailMode = async (letter) => {
    await saveLetterUpdate({ mailMode: letter.mailMode, recipient: letter.recipient })
    return guestLetter
  }

  const refreshCurrentLetterFromS3 = async () => {
    const guestLetterId = ensureGuestLetterId()
    const guestLetterFS = await readFromStorage(guestLetterId, "letter.json")
    if (JSON.stringify(guestLetterFS) !== JSON.stringify(guestLetter)) {
      updateGuestLetterFromS3(guestLetterFS)
    }
  }

  const removeAttachment = async (attachment) => {
    const remainingAttachments = guestLetter.attachments.filter((a) => {
      const itemName = a.fileName
      const attachmentToRemoveName = attachment.fileName
      const isEqual = itemName === attachmentToRemoveName
      return !isEqual
    })
    let resetUpdate = {}
    if (remainingAttachments.length === 0) {
      resetUpdate = {
        template: null,
        recipient: null,
        mailMode: null,
      }
    } else if (guestLetter.template) {
      const template = guestLetter.template
      template.hasValidatedRequiredDocuments = false
      resetUpdate = { template: template }
    }
    await saveLetterUpdate({ attachments: remainingAttachments, ...resetUpdate })
  }

  const addAttachments = async (filesWithPages, hasFulfilledRequiredDocuments) => {
    const id = ensureGuestLetterId()
    const fileWithPagesAsString = await storeAttachments(filesWithPages, id)
    const updatedAttachments = [...guestLetter.attachments, ...fileWithPagesAsString]
    const updatedTemplate = guestLetter.template
    if (updatedTemplate) {
      updatedTemplate.hasValidatedRequiredDocuments = hasFulfilledRequiredDocuments
    }
    await saveLetterUpdate({ attachments: updatedAttachments, template: updatedTemplate })
  }

  const createAddress = async (recipient) => {
    await saveLetterUpdate({ contact: recipient })
    return { id: "guestContactId" }
  }

  const cleanAfterLogin = () => {
    updateGuestLetterId(null)
    updateGuestLetterFromS3(null)
  }

  const updateSenderFromLetterEdition = async ({ sender }) => {
    let senderUpdate = {}
    if (sender) {
      const { password, ...cleanedSender } = sender
      senderUpdate = { ...cleanedSender }
    }

    const docUpdate = { sender: senderUpdate }
    await updateS3Documents(docUpdate)
    await saveLetterUpdate(senderUpdate)
    if (sender) {
      await registerWith(sender.email, sender.password)
      updateHasRegistered(true)
    }
  }
  const updateS3Documents = async (documentsUpdate) => {
    const letterId = ensureGuestLetterId()
    const updatedDocuments = { ...documentsUpdate }
    const content = JSON.stringify(updatedDocuments)
    const blob = new Blob([content], { type: "application/json" })
    const file = new File([blob], `s3Documents.json`, {
      type: "application/json",
      lastModified: new Date(),
    })
    await temporarySave(letterId, file, null)
  }

  const editSender = async (letter) => {
    const { password, ...sender } = letter.sender
    await saveLetterUpdate({ sender: sender })
  }

  async function getPrice(input) {
    const price = await API.graphql({ ...graphqlOperation(getLetterPrice, input), authMode: "API_KEY" })

    if (input.taxMode === "TTC") {
      const priceWithTax = parseFloat(JSON.parse(price.data.getLetterPrice).price)
      const taxAmount = parseFloat(JSON.parse(price.data.getLetterPrice).taxAmount)
      const priceWithoutTax = parseFloat(JSON.parse(price.data.getLetterPrice).priceWithoutTax)
      return {
        priceWithTax,
        taxAmount,
        priceWithoutTax,
      }
    } else {
      const priceString = parseFloat(JSON.parse(price.data.getLetterPrice).priceWithoutTax)
      return priceString
    }
  }

  return (
    <GuestLettersContext.Provider
      value={{
        refreshCurrentLetterFromS3,
        currentLetter: guestLetter,
        createDraft,
        createGuestLetterFromTemplate,
        editRecipient,
        editMailMode,
        removeAttachment,
        addAttachments,
        getPrice,
        createAddress,
        cleanAfterLogin,
        contact: guestLetter && guestLetter.contact,
        sender: guestLetter && guestLetter.sender,
        updateSenderFromLetterEdition,
        editSender,
        isDirectSendPopinVisible,
        updateDirectSendPopinVisible,
        hasRegistered,
      }}
    >
      {props.children}
    </GuestLettersContext.Provider>
  )
}
export const GuestLettersConsumer = GuestLettersContext.Consumer

export const withGuestLetters = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return (
        <GuestLettersConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</GuestLettersConsumer>
      )
    }
  }
  return ComponentWrapperWithAccountPropForChild
}

export class dataURLtoFile {}
