import React from "react"
import { DisconnectedNavBarBlue } from "../../NavBar"

export const AppContentLayout = ({ children }) => <div className="appContent">{children}</div>

export const AppLayoutWithNavbarBlue = ({ children }) => (
  <>
    <DisconnectedNavBarBlue />
    {children}
  </>
)

export const AppContentLayoutWithNavbarBlue = ({ children }) => (
  <div className="appContent">
    <DisconnectedNavBarBlue />
    {children}
  </div>
)
