import React, { createContext, useContext, useState } from "react"
import { Button, Card, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import { LeadGenerationModal } from "./LeadGenerationModal"
import { useLocalStorage, useLocation } from "react-use"
import ProgressiveImage from "react-progressive-graceful-image"
import hpBgFooter from "./assets/images/compressed/hp-bg-footer.png"
import hpBgFooterThumb from "./assets/images/compressed/hp-bg-footer-thumb.svg"
import s from "./components/BottomFaqBanner/styles.module.scss"
import { Link } from "react-router-dom"
import { TemplateBottomBanner } from "./PriceComparator"

export const CallbackContext = createContext()

export const CallbackProvider = (props) => {
  const [isLeadCardVisible, updateLeadCardVisible] = useLocalStorage("isLeadCardVisible", true)
  return (
    <CallbackContext.Provider value={{ isLeadCardVisible, updateLeadCardVisible }}>
      {props.children}
    </CallbackContext.Provider>
  )
}
export const CallbackAndFaqBanner = () => {
  const { isLeadCardVisible, updateLeadCardVisible } = useContext(CallbackContext)
  const [leadGenerationModalVisible, updateLeadGenerationModalVisibility] = useState(false)

  const callMeBackClicked = () => {
    updateLeadGenerationModalVisibility(true)
    updateLeadCardVisible(false)
  }
  const onCloseLeadGenModal = () => {
    updateLeadCardVisible(true)
    updateLeadGenerationModalVisibility(false)
  }

  const closeLeadCard = () => {
    updateLeadCardVisible(false)
  }
  const { pathname } = useLocation()

  if (pathname === "/") return null
  const isModel = pathname.startsWith("/modele-lettre-recommandee")

  const isLeadCardReallyVisible = false

  return (
    <>
      {isModel && <TemplateBottomBanner />}
      <div className="position-relative text-white">
        <ProgressiveImage src={hpBgFooter} placeholder={hpBgFooterThumb}>
          {(src) => (
            <img
              src={src}
              alt="bannière footer"
              style={{ objectFit: "cover", zIndex: "-1", position: "absolute", height: "100%", width: "100%" }}
            />
          )}
        </ProgressiveImage>

        <Container style={{ textAlign: "center", paddingTop: "100px", paddingBottom: "100px" }}>
          <h3 className={s.title}>Une question ?</h3>
          <p className="chapo" style={{ marginBottom: "60px" }}>
            Retrouvez dans notre foire aux questions l'essentiel des réponses les plus couramment recherchées. N'hésitez
            surtout pas à nous contacter si vous avez encore des interrogations après sa lecture !
          </p>
          {pathname !== "/faq" && (
            <Link to="/faq">
              <Button variant="outline-light">Consulter notre FAQ</Button>
            </Link>
          )}
          <Button style={{ marginLeft: "15px" }} variant="outline-light" onClick={callMeBackClicked}>
            Ētre rappelé(e) <FontAwesomeIcon icon={faPhone} />
          </Button>
        </Container>
      </div>
      {isLeadCardReallyVisible && (
        <Card
          md="1"
          style={{
            position: "fixed",
            bottom: "-10px",
            paddingBottom: "10px",
            maxWidth: "300px",
            right: "10px",
            zIndex: "2",
          }}
        >
          <div style={{ display: "flex", flexDirection: "horizontal", justifyContent: "end" }}>
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: "10pt",
              }}
            >
              <h5>Vous avez des questions?</h5>
              <p style={{ marginBottom: "0px" }}>Nos conseillers vous guident pour vos premiers envois</p>
            </div>
            <button
              className="close"
              style={{ padding: "0px", marginRight: "10px", marginTop: "5px", alignSelf: "start" }}
              onClick={closeLeadCard}
            >
              ×
            </button>
          </div>

          <Button
            size="sm"
            variant="outline-primary"
            style={{ marginBottom: "10px", marginLeft: "10px", marginRight: "10px" }}
            onClick={callMeBackClicked}
          >
            Être rappelé(e) <FontAwesomeIcon icon={faPhone} />
          </Button>
        </Card>
      )}
      <LeadGenerationModal
        leadGenerationModalVisible={leadGenerationModalVisible}
        onCloseContactModal={onCloseLeadGenModal}
      />
    </>
  )
}
export const CallbackBanner = () => {
  const { isLeadCardVisible, updateLeadCardVisible } = useContext(CallbackContext)
  const [leadGenerationModalVisible, updateLeadGenerationModalVisibility] = useState(false)

  const callMeBackClicked = () => {
    updateLeadGenerationModalVisibility(true)
    updateLeadCardVisible(false)
  }
  const onCloseLeadGenModal = () => {
    updateLeadCardVisible(true)
    updateLeadGenerationModalVisibility(false)
  }

  const closeLeadCard = () => {
    updateLeadCardVisible(false)
  }

  return (
    <>
      {isLeadCardVisible && (
        <Card
          md="1"
          style={{
            position: "fixed",
            bottom: "-10px",
            paddingBottom: "10px",
            maxWidth: "300px",
            right: "10px",
            zIndex: "2",
          }}
        >
          <div style={{ display: "flex", flexDirection: "horizontal", justifyContent: "end" }}>
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: "10pt",
              }}
            >
              <h5>Vous avez des questions?</h5>
              <p style={{ marginBottom: "0px" }}>Nos conseillers vous guident pour vos premiers envois</p>
            </div>
            <button
              className="close"
              style={{ padding: "0px", marginRight: "10px", marginTop: "5px", alignSelf: "start" }}
              onClick={closeLeadCard}
            >
              ×
            </button>
          </div>

          <Button
            size="sm"
            variant="outline-primary"
            style={{ marginBottom: "10px", marginLeft: "10px", marginRight: "10px" }}
            onClick={callMeBackClicked}
          >
            Être rappelé(e) <FontAwesomeIcon icon={faPhone} />
          </Button>
        </Card>
      )}
      <LeadGenerationModal
        leadGenerationModalVisible={leadGenerationModalVisible}
        onCloseContactModal={onCloseLeadGenModal}
      />
      <div
        style={{
          backgroundColor: "#F5F6F8",
          padding: "50px",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button size="lg" variant="secondary" onClick={callMeBackClicked}>
          Je souhaite être rappelé(e) <FontAwesomeIcon icon={faPhone} />
        </Button>
      </div>
    </>
  )
}
