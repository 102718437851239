import React, { useEffect, useMemo, useState } from "react"

export const PaginationContext = React.createContext()
const PaginationProvider = ({ fetchNextPage, children, pageSize = 10 }) => {
  const [pages, setPages] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoadingCurrentPage, setLoadingCurrentPage] = useState(false)

  const hasNextPage = useMemo(() => pages.length > pageIndex && pages[pageIndex].nextToken, [pageIndex, pages])
  const currentPageItems = useMemo(() => (pages.length > pageIndex ? pages[pageIndex].items : null), [pageIndex, pages])

  useEffect(() => {
    let cancelled = false
    if (pages.length === 0) {
      const loadFirstPage = async () => {
        const firstPage = await fetchNextPage(null, pageSize)
        if (!cancelled) {
          setPages([firstPage])
        }
      }
      loadFirstPage()
        .then(() => console.log("loaded first page"))
        .catch((e) => {
          console.log(`error while loading first page : ${e}`)
        })
    }
    return () => {
      cancelled = true
    }
  }, [pages, fetchNextPage])

  const browseNextPage = async () => {
    if (pages.length > pageIndex + 1) {
      setPageIndex(pageIndex + 1)
    } else {
      const nextPageToken = pages.length > pageIndex ? pages[pageIndex].nextToken : null
      if (nextPageToken) {
        setLoadingCurrentPage(true)
        const nextPage = await fetchNextPage(nextPageToken, 10)
        setLoadingCurrentPage(false)
        setPages(pages.concat([nextPage]))
        setPageIndex(pageIndex + 1)
      }
    }
  }

  const browsePreviousPage = async () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  }

  const invalidateFirstPage = async () => {
    setPages([])
    setPageIndex(0)
    await browseNextPage()
  }
  return (
    <PaginationContext.Provider
      value={{
        invalidateFirstPage,
        currentPageItems,
        isLoadingCurrentPage,
        pageIndex,
        hasNextPage,
        browseNextPage,
        browsePreviousPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}
export const PaginationConsumer = PaginationContext.Consumer
//Use this function on your provider to decorate it with paging feature
export const withPagination = (fetchNextPage, Component, pageSize = 10) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props

      return (
        <PaginationProvider fetchNextPage={fetchNextPage} pageSize={pageSize}>
          <PaginationConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</PaginationConsumer>
        </PaginationProvider>
      )
    }
  }
  return ComponentWrapperWithAccountPropForChild
}
