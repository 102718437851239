import React, { createContext, useEffect, useMemo, useState } from "react"
import { withAccount } from "./AccountComponent"
import { createPaymentInDb, fetchLastPayments } from "./api/payments"
import { apiCreditsNewBasket, apiCreditsGetBasket } from "./BasketApi"

export const PaymentsContext = createContext(null)

const useLastPaymentsDataSource = (accountId) => {
  const [isLoading, setLoading] = useState(true)
  const [lastPayments, setLastPayments] = useState(null)

  useEffect(() => {
    let cancelled = false //We use this to stop modifying an unmounted component
    const getLastPayments = async (accountId) => {
      const payments = await fetchLastPayments(accountId)
      if (!cancelled) {
        setLoading(false)
        setLastPayments(payments.filter((p) => p.basket == null))
      }
    }
    if (accountId) {
      setLoading(true)
      getLastPayments(accountId).catch((e) => {
        console.log(`Error while initializing : ${e}`)
      })
    } else {
      setLastPayments(null)
      setLoading(true)
    }
    return () => {
      cancelled = true
    }
  }, [accountId, fetchLastPayments, setLastPayments, setLoading])

  const refreshLastPayments = async () => {
    const payments = await fetchLastPayments(accountId)
    setLastPayments(payments.filter((p) => p.basket == null))
  }
  return {
    refreshLastPayments,
    isLoadingLastPayments: isLoading,
    lastPayments,
  }
}

export const PaymentsProvider = withAccount(({ children, account }) => {
  const accountId = useMemo(() => account && account.id, [account])

  const { refreshLastPayments, isLoadingLastPayments, lastPayments } = useLastPaymentsDataSource(accountId)

  const createPayment = async (payment, basketId = "credits") => {
    const orderId = payment.orderID

      // check if account already has a basketCredits field
      let currentCreditsBasket = await apiCreditsGetBasket(accountId)

      console.log("--- currentCreditsBasket --->", currentCreditsBasket)
      let currentCreditsBasketId = null

      // if currentCreditsBasket is undefined
      if (currentCreditsBasket === undefined) {
        currentCreditsBasket = await apiCreditsNewBasket(accountId)
        currentCreditsBasketId = currentCreditsBasket.data.createBasket.id
      } else {
        currentCreditsBasketId = currentCreditsBasket.id
      }
    if (basketId === "credits") {
      await createPaymentInDb(orderId, currentCreditsBasketId)
    } else {
      await createPaymentInDb(orderId, basketId)
    }
  }

  return (
    <PaymentsContext.Provider
      value={{
        refreshLastPayments,
        isLoadingLastPayments,
        lastPayments,
        createPayment,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  )
})

export const PaymentsConsumer = PaymentsContext.Consumer

export const withPayments = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <PaymentsConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</PaymentsConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}
