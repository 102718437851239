export const getAccountWithoutLetters = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      createdAt
      updatedAt
      id
      username
      accountOptions {
        isMultipleSenderAddressesActivated
      }
      sender {
        formOfAddress
        firstName
        lastName
        isProfessional
        companyName
        addressPrefix
        address
        zipCode
        city
        email
        phoneNumber
        instructions
        phoneNumberLandline
      }
      idDocument {
        parts {
          bucket
          region
          key
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
          }
          nature
        }
      }
      defaultAddressHolderBackground {
        parts {
          bucket
          region
          key
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
          }
          nature
        }
      }
      defaultSignature {
        parts {
          bucket
          region
          key
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
          }
          nature
        }
      }
      kbis {
        parts {
          bucket
          region
          key
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
          }
          nature
        }
      }
      senderAddresses {
        items {
          id
          createdAt
          updatedAt
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            version
            owner
          }
          owner
        }
        nextToken
      }
      contacts {
        items {
          id
          createdAt
          updatedAt
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            version
            owner
          }
          owner
        }
        nextToken
      }
      credits
      freeLetters
      payments {
        items {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            version
            owner
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
        }
        nextToken
      }
      prepaidCodes {
        items {
          id
          useddate
          consumeddate
          createdAt
          updatedAt
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            version
            owner
          }
          mailmodes
          owner
        }
        nextToken
      }
      creditOrders {
        items {
          createdAt
          updatedAt
          id
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            version
            owner
          }
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          showAsPurchaseOrder
          remainingCredits
          amountCreditOrderBaskets {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
            }
            nextToken
          }
          version
          owner
        }
        nextToken
      }
      version
      owner
    }
  }
`
