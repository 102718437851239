import React, { useState } from "react"
import "./styles/app.scss"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { AuthProvider } from "./components/AuthComponent"
import { Helmet } from "react-helmet"
import { MailModeProvider } from "./components/MailModeComponent"
import { LoginContextProvider } from "./LoginModal"
import "./styles/_app.scss"
import CookieConsent from "react-cookie-consent"
import ScrollToTop from "./components/ui/ScrollToTop"
import { GuestLettersProvider } from "./components/GuestLettersComponent"
import { FullScreenLoaderProvider } from "./components/FullScreenLoader"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import { TemplateProvider } from "./templates/TemplateComponent"
import { TemplateNavigationProvider } from "./templates/TemplateNavigationProvider"
import { CanonicalLinkProvider } from "./components/CanonicalLinkComponent"
import { AnalyticsProvider } from "./components/AnalyticsComponent"
import { CallbackProvider } from "./CallbackBanner"
import ConnectedAppRoutes from "./routes/ConnectedAppRoutes"

const App = () => {
  const [hasAcceptedCookie, updateCookieConsent] = useState(false)

  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Courriers recommandés en ligne</title>
        <meta
          name="description"
          content="Envoyez vos courriers en ligne sans abonnement. Des recommandés électroniques pour éviter le déplacement à votre destinataire également. Vos lettres éco, imprimées et mises sous pli, au prix du timbre."
        />
        <link rel="stylesheet" href="https://api.lyra.com/static/js/krypton-client/V4.0/ext/classic-reset.css" />
        <script src="https://api.lyra.com/static/js/krypton-client/V4.0/ext/classic.js" />
      </Helmet>
      <ScrollToTop />
      <CanonicalLinkProvider>
        <AnalyticsProvider>
          <Switch>
            <LoginContextProvider>
              <I18nextProvider i18n={i18n}>
                <FullScreenLoaderProvider>
                  <AuthProvider>
                    <MailModeProvider>
                      <GuestLettersProvider>
                        <TemplateProvider>
                          <CallbackProvider>
                            <TemplateNavigationProvider>
                              <ConnectedAppRoutes />
                              <CookieConsent
                                buttonText="Accepter"
                                enableDeclineButton={true}
                                declineButtonText="Refuser"
                                onAccept={() => updateCookieConsent(true)}
                                onDecline={() => updateCookieConsent(false)}
                              >
                                Ce site web utilise des cookies de statistiques pour améliorer l'expérience utilisateur
                                uniquement (aucune donnée collectée n'est utilisée pour personnaliser l'affichage de
                                publicités ciblées).
                              </CookieConsent>
                            </TemplateNavigationProvider>
                          </CallbackProvider>
                        </TemplateProvider>
                      </GuestLettersProvider>
                    </MailModeProvider>
                  </AuthProvider>
                </FullScreenLoaderProvider>
              </I18nextProvider>
            </LoginContextProvider>
          </Switch>
        </AnalyticsProvider>
      </CanonicalLinkProvider>
    </Router>
  )
}

export default App
