import React, { useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { listMailModes } from "../graphql/queries"

const listModes = async () => {
  try {
    // The price should not be defined client-side, but it's a nice way to cache the dynamodb value.
    const mailModes = await API.graphql({ ...graphqlOperation(listMailModes), authMode: "AWS_IAM" })
    return mailModes.data.listMailModes.items.sort((a, b) => a.sortOrder - b.sortOrder)
  } catch (e) {
    console.error("Could not read mailModes", e)
  }
}

export const MailModeContext = React.createContext()
export const MailModeProvider = ({ children }) => {
  const [mailModes, setMailModes] = useState(null)
  const [isLoadingMailModes, updateIsLoadingMailMode] = useState(null)
  const [errorLoadingMailModes, updateErrorLoadingMailModes] = useState(null)
  useEffect(() => {
    async function fetchModes() {
      const array = await listModes()
      setMailModes(array)
    }
    updateIsLoadingMailMode(true)
    fetchModes()
      .then(() => {
        updateIsLoadingMailMode(false)
        updateErrorLoadingMailModes(null)
      })
      .catch((e) => {
        updateIsLoadingMailMode(false)
        updateErrorLoadingMailModes(e)
      })
  }, [])

  return (
    <MailModeContext.Provider value={{ mailModes, isLoadingMailModes, errorLoadingMailModes }}>
      {children}
    </MailModeContext.Provider>
  )
}

export const MailModeConsumer = MailModeContext.Consumer

export const withMailModes = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <MailModeConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</MailModeConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}
