import React from "react"
import s from "./socialLink.module.scss"
import clsx from "clsx"

const SocialLink = ({ children, className, ...props }) => {
  return (
    <a className={clsx(s.socialLink, className)} {...props}>
      {children}
    </a>
  )
}

export default SocialLink
