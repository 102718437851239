import React from "react"
import { CustomToggle, CustomMenu } from "./CustomDropDown"
import { Dropdown, Nav, Button } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"

const ConnectedMenuMobile = ({ logout }) => {
  return (
    <div className="d-xs-block d-lg-none">
      <Dropdown className="w-100">
        <Dropdown.Toggle as={CustomToggle} variant="dark" className="w-100 text-left">
          Mon compte
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu} className="w-100">
          <Dropdown.Item>
            <LinkContainer to="/account">
              <Nav.Link href="/account">Mes informations</Nav.Link>
            </LinkContainer>
          </Dropdown.Item>
          <Dropdown.Item>
            <LinkContainer to="/letters">
              <Nav.Link href="/account">Historique de mes envois</Nav.Link>
            </LinkContainer>
          </Dropdown.Item>
          <Dropdown.Item>
            <LinkContainer to="/contacts">
              <Nav.Link href="/account">Mes contacts</Nav.Link>
            </LinkContainer>
          </Dropdown.Item>
          <Dropdown.Item>
            <LinkContainer to="/purchases">
              <Nav.Link href="/purchases">Mes factures</Nav.Link>
            </LinkContainer>
          </Dropdown.Item>
          <Dropdown.Item>
            <LinkContainer to="/credits">
              <Nav.Link href="/credits">Mes crédits</Nav.Link>
            </LinkContainer>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button
              variant="outline-primary mt-4"
              onClick={() => {
                logout()
              }}
            >
              Se déconnecter
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ConnectedMenuMobile
