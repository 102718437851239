import { PurchaseProvider } from "./PurchaseComponent"
import { PaymentsProvider } from "./PaymentComponent"
import React from "react"

/**
 * This is a provider that combines two providers
 * @param children
 * @returns {*}
 * @constructor
 */
export const PurchaseAndPaymentsProvider = ({ children }) => {
  return (
    <PurchaseProvider>
      <PaymentsProvider>{children}</PaymentsProvider>
    </PurchaseProvider>
  )
}
