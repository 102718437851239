import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import exampleBodyTemplates from "./exampleBody"

const exampleRecipients = [
  {
    id: "1",
    firstName: "Bla",
    templates: [],
  },
]

export const TemplateContext = React.createContext()
export const TemplateProvider = ({ children }) => {
  const [letterTemplates, updateLetterTemplates] = useState([])
  const [bodyTemplates, updateBodyTemplates] = useState(exampleBodyTemplates)
  const [recipients, updateRecipients] = useState(exampleRecipients)
  const getLetterTemplate = async (id) => {
    return letterTemplates.find((lt) => lt.id === id)
  }

  const getBodyTemplate = async (id) => {
    return bodyTemplates.find((bt) => bt.id === id)
  }

  const createRecipient = async (input) => {
    const templates = input.templates || []
    const id = uuid()
    const newRecipientContent = { id, templates, ...input }
    recipients.push(newRecipientContent)
    updateRecipients(recipients)
    return id
  }
  const editRecipient = async (input) => {
    const recipientIndex = recipients.findIndex((r) => r.id === input.id)
    recipients[recipientIndex] = input
    updateRecipients(recipients)
  }

  const getRecipient = async (id) => {
    return recipients.find((r) => r.id === id)
  }

  return (
    <TemplateContext.Provider
      value={{
        letterTemplates,
        bodyTemplates,
        recipients,
        getRecipient,
        createRecipient,
        editRecipient,
        getLetterTemplate,
        getBodyTemplate,
      }}
    >
      {<>{children}</>}
    </TemplateContext.Provider>
  )
}

export const TemplateConsumer = TemplateContext.Consumer

export const withTemplate = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <TemplateConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</TemplateConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}
