import { Col, Image, Row } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import React from "react"
import hpCliquezPostezLogo from "./assets/images/hp-cliquez-postez-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import SocialLink from "./components/ui/SocialLink"
import s from "./bottomNavbar.module.scss"

export const BottomNavBar = () => {
  return (
    <nav className="navbar bottomNavbar navbar-dark text-center text-sm-left">
      <Row className="w-100 justify-content-between">
        <Col sm="auto">
          <a className="navbar-brand" href="/" aria-label="Accueil de cliquez postez">
            <Image alt="Cliquez Postez" src={hpCliquezPostezLogo} widht="193px" height="23px" />
          </a>
          <div className="my-2 d-flex justify-content-center justify-content-sm-start">
            <SocialLink
              href="https://www.facebook.com/CliquezPostez"
              target="_blank"
              rel="noreferrer noopener"
              className="mr-2"
              aria-label="Page Facebook de CliquezPostez"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </SocialLink>
            <SocialLink
              href="https://fr.linkedin.com/company/cliquezpostez-com"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Page Linkedin de CliquezPostez"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </SocialLink>
          </div>
        </Col>
        <Col xs={12} sm="auto" style={{ paddingTop: "10px" }}>
          <p className={s.footerItem}>
            <LinkContainer to="/">
              <a>Accueil</a>
            </LinkContainer>
          </p>
          <p className={s.footerItem}>
            <LinkContainer to="/a-propos">
              <a>Qui sommes-nous?</a>
            </LinkContainer>
          </p>
          <p className={s.footerItem}>
            <LinkContainer to="/lettre-recommandee-electronique">
              <a>LRE</a>
            </LinkContainer>
          </p>
        </Col>
        <Col xs={12} sm="auto" style={{ paddingTop: "10px" }}>
          <p className={s.footerItem}>
            <LinkContainer to="/qualification-eidas">
              <a>Certifications</a>
            </LinkContainer>
          </p>
          <p className={s.footerItem}>
            <LinkContainer to="/tarif-lettre-recommandee">
              <a>Tarifs</a>
            </LinkContainer>
          </p>
          <p className={s.footerItem}>
            <LinkContainer to="/faq">
              <a>FAQ</a>
            </LinkContainer>
          </p>
        </Col>
        <Col xs={12} sm="auto" style={{ paddingTop: "10px" }}>
          <p className={s.footerItem}>
            <LinkContainer to="/mentions-legales">
              <a>Mentions légales</a>
            </LinkContainer>
          </p>
          <p className={s.footerItem}>
            <LinkContainer to="/cgv">
              <a>Conditions générales</a>
            </LinkContainer>
          </p>
        </Col>
        <Col xs={12} sm="auto" style={{ paddingTop: "10px" }}>
          <h5 className={s.contact}>Contactez-nous</h5>
          <p className={s.footerItem}>
            <a href="mailto:contact@cliquezpostez.com">Mail : contact@cliquezpostez.com</a>
          </p>
          <p className={s.footerItem}>
            <a href="tel:019666116">Tel: 06 19 66 61 16</a>
          </p>
        </Col>
      </Row>
      <p
        className={s.footerItem}
        style={{ fontSize: "12pt", opacity: "0.3", marginTop: "0.5rem", marginBottom: "0.5rem" }}
      >
        Copyright © 2020 Cliquezpostez.com. Tous droits réservés
      </p>
    </nav>
  )
}
