import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { validateLeadForm } from "./ContactForm"
import API, { graphqlOperation } from "@aws-amplify/api"
import { v4 as uuid } from "uuid"
import { createLead } from "./graphql/subs/LeadMutations"
import { AnalyticsContext } from "./components/AnalyticsComponent"

const apiCreateLead = async (leadInput) => {
  leadInput.id = uuid()
  const result = await API.graphql({ ...graphqlOperation(createLead, { input: leadInput }), authMode: "AWS_IAM" })
  return result.data.createLead
}

export const LeadGenerationModal = ({ leadGenerationModalVisible, onCloseContactModal }) => {
  const { onCallbackFormSubmitted } = useContext(AnalyticsContext)
  const [leadSaveError, updateLeadSaveError] = useState(null)
  const [leadForm, updateLeadForm] = useState({})
  const [state, updateState] = useState("idle") //idle, saving, save
  const [validationErrors, updateValidationErrors] = useState({})

  useEffect(() => {
    updateValidationErrors(validateLeadForm(leadForm, false))
  }, [leadForm])

  const hasError = useMemo(() => {
    const lastCheckErrors = validateLeadForm(leadForm, true)
    return Object.keys(lastCheckErrors).filter((k) => lastCheckErrors[k]).length > 0
  }, [leadForm])

  const onDismissError = () => {
    updateLeadSaveError(null)
  }

  const addAnotherLead = () => {
    updateLeadForm({})
    updateState("idle")
  }

  const submitLead = async () => {
    updateLeadSaveError(null)
    try {
      updateState("saving")
      await apiCreateLead(leadForm)
      onCallbackFormSubmitted()
      updateState("saved")
    } catch (e) {
      updateState("idle")
      updateLeadSaveError(e)
    }
  }

  return (
    <Modal show={leadGenerationModalVisible} onHide={onCloseContactModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Je souhaite être rappelé</Modal.Title>
      </Modal.Header>
      {state === "idle" && (
        <>
          <Modal.Body>
            <p style={{ fontSize: "1.1rem" }}>
              Nos conseillers vous rappellent du lundi au vendredi de 9h à 17h. Vous pouvez également nous joindre
              directement au 06 19 66 61 16 (numéros non surtaxés).
            </p>
            {leadSaveError ? (
              <Alert dismissible={true} onClose={onDismissError} variant="danger">
                {JSON.stringify(leadSaveError)}
              </Alert>
            ) : null}
            <LeadForm {...{ leadForm, validationErrors, updateLeadForm }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={onCloseContactModal}>
              Annuler
            </Button>
            <Button variant="primary" onClick={submitLead} disabled={hasError}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </>
      )}
      {state === "saving" && (
        <>
          <Modal.Body>
            <Spinner />
          </Modal.Body>
        </>
      )}
      {state === "saved" && (
        <>
          <Modal.Body>
            <Alert variant="success">
              Vos coordonnées ont bien été envoyées. Vous recevrez un appel de notre service commercial dès que possible
              (du lundi au vendredi de 9h à 17h). Vous pouvez également nous joindre directement au 06 19 66 61 16
              (numéros non surtaxés).
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onCloseContactModal}>
              Fermer
            </Button>
            <Button variant="secondary" onClick={addAnotherLead}>
              Ajouter un rappel
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}
const LeadForm = ({ leadForm, validationErrors, updateLeadForm }) => {
  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    let updatedValue = value
    if (updatedValue === "") {
      updatedValue = undefined
    }
    updateLeadForm({ ...leadForm, [evt.target.name]: updatedValue })
  }

  return (
    <Form key="addressForm">
      <Form.Group controlId="phoneGroup">
        <Form.Label>TELEPHONE PORTABLE</Form.Label>
        <Form.Control
          type="phone"
          name="phoneNumber"
          onChange={handleChange}
          isInvalid={validationErrors.phoneNumber}
          defaultValue={leadForm.phoneNumber}
        />
        <Form.Control.Feedback type="invalid">{validationErrors.phoneNumber}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="nameGroup">
        <Form.Label>PRENOM NOM</Form.Label>
        <Form.Control
          type="text"
          name="name"
          onChange={handleChange}
          isInvalid={validationErrors.name}
          value={leadForm.name}
        />
        <Form.Control.Feedback type="invalid">{validationErrors.name}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="emailGroup">
        <Form.Label>EMAIL (optionnel)</Form.Label>
        <Form.Control
          type="email"
          name="email"
          onChange={handleChange}
          isInvalid={validationErrors.email}
          defaultValue={leadForm.email}
        />
        <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="commentGroup">
        <Form.Label>COMMENTAIRE (optionnel)</Form.Label>
        <Form.Control
          type="text"
          name="comment"
          onChange={handleChange}
          isInvalid={validationErrors.comment}
          defaultValue="Je souhaite être rappelé entre 17h et 18h"
        />
        <Form.Control.Feedback type="invalid">{validationErrors.comment}</Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}
