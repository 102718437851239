import React, { createContext, useContext, useEffect, useState } from "react"
import { Alert, Button, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { AuthContext } from "./components/AuthComponent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye"
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash"
import { LinkContainer } from "react-router-bootstrap"
import { useHistory } from "react-router-dom"
import { AnalyticsContext } from "./components/AnalyticsComponent"

export const LoginContext = createContext({ visible: false, showModal: () => {}, closeModal: () => {} })

export const LoginContextProvider = (props) => {
  const [visible, setVisible] = useState(false)
  const [redirectTarget, setRedirectTarget] = useState(null)
  const [dismissTarget, setDismissTarget] = useState(null)
  const history = useHistory()

  const showModal = (target, dismiss = null) => {
    setVisible(true)
    setRedirectTarget(target)
    setDismissTarget(dismiss)
  }

  const closeModal = () => {
    setVisible(false)
    if (dismissTarget) {
      history.push(dismissTarget)
    }
  }
  return (
    <LoginContext.Provider value={{ visible, showModal, closeModal, redirectTarget }}>
      {props.children}
    </LoginContext.Provider>
  )
}

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  let re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const LoginForm = ({ openRecoverPassword, signinWith, resendSignUp, loginFromSigninForm }) => {
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({ global: null, email: null, password: null, resend: null })

  const [passwordVisibile, showPassword] = useState(false)

  const handlePasswordVisibilityChange = () => {
    showPassword(!passwordVisibile)
  }

  useEffect(() => {
    if (loginFromSigninForm) {
      showPassword(false)
      formData.email = loginFromSigninForm.email
      formData.password = loginFromSigninForm.password
      doSubmit()
    }
  }, [loginFromSigninForm])

  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    formData[evt.target.name] = value
    setFormData(formData)
    const nErrors = {}
    nErrors.global = null
    if (!formData.password || formData.password.length < 2) {
      nErrors.password = "Veuillez entrer un mot de passe"
    } else {
      nErrors.password = null
    }
    if (!formData.email) {
      nErrors.email = "Veuillez entrer un email"
    } else if (!validateEmail(formData.email)) {
      nErrors.email = "Veuillez entrer un email valide"
    } else {
      nErrors.email = null
    }
    setErrors({ ...errors, ...nErrors })
  }

  async function doSubmit() {
    try {
      await signinWith(formData.email, formData.password)
      setErrors({ global: null })
    } catch (err) {
      console.error(err)
      if (err.code === "UserNotConfirmedException") {
        setErrors({ ...errors, global: "Votre adresse email n'est pas vérifiée.", resend: true })
      } else {
        setErrors({ ...errors, global: err.message })
      }
    }
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    await doSubmit()
  }

  const handleResendSign = async (evt) => {
    evt.preventDefault()
    try {
      await resendSignUp(formData.email)
      setErrors({ global: null })
    } catch (err) {
      console.error(err)
      setErrors({ ...errors, global: err.message })
    }
  }

  const clearAlert = () => {
    setErrors({ ...errors, global: null })
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Label>
        <h5>Connexion</h5>
      </Form.Label>
      {errors.global ? (
        <Alert key="error" variant="danger" onClose={clearAlert} dismissible>
          {errors.global}
        </Alert>
      ) : null}
      {errors.resend ? (
        <Button key="resend" variant="info" onClick={handleResendSign}>
          Renvoyer la confirmation
        </Button>
      ) : null}
      <Form.Group controlId="loginEmail">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type="email"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          isInvalid={errors.email}
          value={formData.email}
        />
        {errors.email ? <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> : null}
      </Form.Group>
      <Form.Group controlId="loginPassword">
        <Form.Label>Mot de passe*</Form.Label>
        <InputGroup className="">
          <Form.Control
            type={passwordVisibile ? "text" : "password"}
            name="password"
            autoComplete="password-current"
            onChange={handleChange}
            isInvalid={errors.password}
            value={formData.password}
            className="clear"
          />
          <InputGroup.Append>
            <Button variant="secondary" onClick={handlePasswordVisibilityChange} className="faEye">
              <FontAwesomeIcon
                icon={passwordVisibile ? faEye : faEyeSlash}
                style={{
                  marginTop: "5px",
                }}
              />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {errors.password ? <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> : null}
        <Button
          className="text-muted, float-right"
          style={{ fontSize: "12pt" }}
          variant="link"
          onClick={() => openRecoverPassword()}
        >
          Mot de passe perdu?
        </Button>
      </Form.Group>
      <Form.Group controlId="loginPassword">
        <Button style={{ marginTop: "30px" }} type="submit" variant="dark" disabled={errors.password || errors.email}>
          Se connecter
        </Button>
      </Form.Group>
    </Form>
  )
}

const RegisterForm = ({ registerWith, switchToSignin }) => {
  const { onCreateAccountClicked } = useContext(AnalyticsContext)
  const [formData, setFormData] = useState({})
  const [message, setMessage] = useState(null)
  const [errors, setErrors] = useState({ email: null, password: null, global: null })
  const [passwordVisibile, showPassword] = useState(false)

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    try {
      onCreateAccountClicked()
      await registerWith(formData.email, formData.password)
      setMessage(
        `Un email de confirmation a été envoyé à ${formData.email}. Veuillez cliquer sur le lien pour continuer`
      )
    } catch (err) {
      console.error(err)
      if (err.code === "UsernameExistsException") {
        setErrors({
          ...errors,
          email: "Un compte existe déjà pour cette adresse email - Connexion à ce compte en cours",
        })
        switchToSignin(formData.email, formData.password)
      } else if (err.code === "InvalidParameterException") {
        setErrors({
          ...errors,
          email:
            "Un des paramètre est invalide. Veuillez vérifier que " + "votre mot de passe fait plus de 6 caractères.",
        })
      } else {
        setErrors({ ...errors, email: err.message })
      }
    }
  }

  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    formData[evt.target.name] = value
    setFormData(formData)
    const nErrors = {}
    nErrors.global = null
    if (!formData.password || formData.password.length < 2) {
      nErrors.password = "Veuillez entrer un mot de passe"
    } else if (formData.password.length < 8) {
      nErrors.password = "Le mot de passe doit contenir au moins 8 caractères"
    } else {
      nErrors.password = null
    }
    if (!formData.email) {
      nErrors.email = "Veuillez entrer un email"
    } else if (!validateEmail(formData.email)) {
      nErrors.email = "Veuillez entrer un email valide"
    } else {
      nErrors.email = null
    }
    console.error(evt)
    setErrors({ ...errors, ...nErrors })
  }

  const handlePasswordVisibilityChange = () => {
    showPassword(!passwordVisibile)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Label>
        <h5>S'enregistrer</h5>
      </Form.Label>
      {message ? (
        <Alert key="success" variant="primary">
          {message}
        </Alert>
      ) : null}
      <Form.Group controlId="registerEmail">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type="email"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          isInvalid={!!errors.email}
          defaultValue={formData.email}
        />
        {errors.email ? <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> : null}
      </Form.Group>
      <Form.Group controlId="registerPassword">
        <Form.Label>Mot de passe*</Form.Label>
        <InputGroup className="">
          <Form.Control
            type={passwordVisibile ? "text" : "password"}
            name="password"
            autoComplete="password-new"
            onChange={handleChange}
            isInvalid={!!errors.password}
            defaultValue={formData.password}
          />
          <InputGroup.Append>
            <Button variant="secondary" onClick={handlePasswordVisibilityChange} className="faEye">
              <FontAwesomeIcon
                icon={passwordVisibile ? faEye : faEyeSlash}
                style={{
                  marginTop: "5px",
                }}
              />
            </Button>
          </InputGroup.Append>
          {errors.password ? <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> : null}
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Text className="text-muted">
          Vos données personnelles seront utilisées uniquement dans le cadre de nos services.{" "}
          <LinkContainer to="/cgvs">
            <a href="/cgvs">Consulter nos conditions générales</a>
          </LinkContainer>
          .
        </Form.Text>
        <Button variant="dark" type="submit" disabled={errors.password || errors.email}>
          S'enregister
        </Button>
      </Form.Group>
    </Form>
  )
}
export const LoginContainer = ({ message }) => {
  const [loginOrRecover, switchMode] = useState("login")
  const { registerWith, signinWith, resendSignUp } = useContext(AuthContext)
  const history = useHistory()

  const signin = async (email, password) => {
    await signinWith(email, password)
    history.push("/createLetter")
  }

  return (
    <Container>
      {message ? <Alert variant="info">{message}</Alert> : null}
      {loginOrRecover === "login" ? (
        <Row>
          <Col md="6">
            <LoginForm
              openRecoverPassword={() => switchMode("recover")}
              signinWith={signin}
              resendSignUp={resendSignUp}
            />
          </Col>
          <Col md="6">
            <RegisterForm registerWith={registerWith} signinWith={signinWith} />
          </Col>
        </Row>
      ) : (
        <RecoverPassword
          switchToLogin={() => {
            switchMode("login")
          }}
        />
      )}
    </Container>
  )
}
export default function LoginModal() {
  const { visible, closeModal, redirectTarget } = useContext(LoginContext)
  const [loginOrRecover, switchMode] = useState("login")
  const [loginFromSigninForm, updateLoginFromSigninForm] = useState(null)
  const { registerWith, signinWith, resendSignUp } = useContext(AuthContext)
  const history = useHistory()

  const signin = async (email, password) => {
    await signinWith(email, password)
    closeModal()
    if (redirectTarget) {
      history.push(redirectTarget)
    } else {
      history.push("/dashboard")
    }
  }

  const switchToSignin = (email, password) => {
    updateLoginFromSigninForm({ email, password })
  }

  return (
    <Modal
      show={visible}
      onHide={() => {
        closeModal(false)
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Mon compte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        `
        {loginOrRecover === "login" ? (
          <Row>
            <Col md="6">
              <LoginForm
                openRecoverPassword={() => {
                  switchMode("recover")
                }}
                signinWith={signin}
                loginFromSigninForm={loginFromSigninForm}
                resendSignUp={resendSignUp}
              />
            </Col>
            <Col md="6">
              <RegisterForm registerWith={registerWith} switchToSignin={switchToSignin} />
            </Col>
          </Row>
        ) : (
          <RecoverPassword
            switchToLogin={() => {
              switchMode("login")
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}
const FormEmail = ({ handleSubmitEmail, errors, formData, setFormData, setErrors }) => {
  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    formData[evt.target.name] = value
    setFormData(formData)
    const nErrors = {}
    nErrors.global = null
    if (!formData.email) {
      nErrors.email = "Veuillez entrer un email"
    } else if (!validateEmail(formData.email)) {
      nErrors.email = "Veuillez entrer un email valide"
    } else {
      nErrors.email = null
    }
    setErrors({ ...errors, ...nErrors })
  }

  return (
    <Form noValidate onSubmit={handleSubmitEmail}>
      <Form.Label>
        <h5>Récupération du mot de passe</h5>
      </Form.Label>
      <Form.Group controlId="resetEmail">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type="email"
          name="email"
          onChange={handleChange}
          isInvalid={!!errors.email}
          value={formData.email}
        />
        {errors.email ? <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> : null}
      </Form.Group>
      <Button style={{ marginTop: "30px" }} variant="primary" type="submit" disabled={errors.email}>
        Envoyer l'email de récupération
      </Button>
    </Form>
  )
}

const FormCodeAndPassword = ({ handleSubmitCodeAndPassword, setErrors, errors, formData, setFormData }) => {
  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    formData[evt.target.name] = value
    setFormData(formData)
    const nErrors = {}
    nErrors.global = null
    if (!formData.code) {
      nErrors.code = "Veuillez entrer le code"
    } else {
      nErrors.code = null
    }
    setErrors({ ...errors, ...nErrors })
  }

  const [passwordVisibile, showPassword] = useState(false)
  const handlePasswordVisibilityChange = () => {
    showPassword(!passwordVisibile)
  }

  return (
    <Form noValidate onSubmit={handleSubmitCodeAndPassword}>
      <Alert variant="success">
        Nous vous avons envoyé un code de vérification par email afin que vous puissiez choisir un nouveau mot de passe.
      </Alert>
      <Form.Group controlId="resetCode">
        <Form.Label>Code*</Form.Label>
        <Form.Control
          type="number"
          name="code"
          onChange={handleChange}
          isInvalid={!!errors.code}
          value={formData.code}
        />
        {errors.code ? <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback> : null}
      </Form.Group>
      <Form.Group controlId="resetPassword">
        <Form.Label>Nouveau mot de passe*</Form.Label>
        <InputGroup className="">
          <Form.Control
            type={passwordVisibile ? "text" : "password"}
            name="password"
            onChange={handleChange}
            isInvalid={!!errors.password}
            value={formData.password}
          />

          <InputGroup.Append>
            <Button variant="secondary" onClick={handlePasswordVisibilityChange} className="faEye">
              <FontAwesomeIcon
                icon={passwordVisibile ? faEye : faEyeSlash}
                style={{
                  marginTop: "5px",
                }}
              />
            </Button>
          </InputGroup.Append>
          {errors.password ? <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> : null}
        </InputGroup>
      </Form.Group>
      <Button style={{ marginTop: "30px" }} variant="primary" type="submit" disabled={errors.password || errors.code}>
        Changer le mot de passe
      </Button>
    </Form>
  )
}

export const RecoverPassword = ({ switchToLogin }) => {
  const { resetPassword, resendSignUp, continuePasswordFlow } = useContext(AuthContext)
  const [formData, setFormData] = useState({ email: "", code: "", password: "", resend: null })
  const [errors, setErrors] = useState({ email: null, global: null })
  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState("emailInput")

  const handleSubmitEmail = async (evt) => {
    evt.preventDefault()
    try {
      await resetPassword(formData.email)
      setStatus("codeInput")
      setErrors({ global: null })
    } catch (err) {
      if (
        err.code === "InvalidParameterException" &&
        err.message === "Cannot reset password for the user as there is no registered/verified email or phone_number"
      ) {
        setErrors({
          ...errors,
          global:
            "Impossible de réinitialiser le mot de passe. Si vous n'avez pas validé " +
            "votre email, cliquez sur le bouton ci-dessous pour renvoyer l'email " +
            "de validation.",
          resend: true,
        })
      } else if (err.code === "LimitExceededException") {
        setErrors({
          ...errors,
          email: "Vous venez d'atteindre le nombre maximal d'essais, merci de réessayer " + "dans quelques minutes.",
        })
      } else if (err.code === "UserNotFoundException") {
        setErrors({
          ...errors,
          global: "Pas de compte existant avec cet email",
          resend: false,
        })
      } else {
        setErrors({ ...errors, global: err.message })
      }
    }
  }

  const handleSubmitCodeAndPassword = async (evt) => {
    evt.preventDefault()
    try {
      await continuePasswordFlow(formData.email, formData.code, formData.password)
      setStatus("success")
      setMessage("Votre mot de passe a bien été mis à jour")
      setErrors({ global: null })
    } catch (err) {
      setErrors({ ...errors, global: err.message })
    }
  }

  const handleResendSign = async (evt) => {
    evt.preventDefault()
    try {
      await resendSignUp(formData.email)
      setErrors({ global: null })
    } catch (err) {
      setErrors({ ...errors, global: err.message })
    }
  }

  return (
    <>
      {message ? (
        <Alert key="success" variant="success">
          {message}
        </Alert>
      ) : null}
      {errors.global ? (
        <Alert key="error" variant="danger">
          {errors.global}
        </Alert>
      ) : null}
      {errors.resend ? (
        <Button key="resend" variant="info" onClick={handleResendSign}>
          Renvoyer la confirmation
        </Button>
      ) : null}
      {status === "codeInput" ? (
        <FormCodeAndPassword
          errors={errors}
          setErrors={setErrors}
          formData={formData}
          setFormData={setFormData}
          handleSubmitCodeAndPassword={handleSubmitCodeAndPassword}
        />
      ) : null}
      {status === "emailInput" ? (
        <FormEmail
          errors={errors}
          setErrors={setErrors}
          formData={formData}
          setFormData={setFormData}
          handleSubmitEmail={handleSubmitEmail}
        />
      ) : null}
      {status === "success" ? (
        <>
          <Button
            style={{ marginTop: "30px" }}
            variant="primary"
            onClick={() => {
              switchToLogin()
            }}
          >
            Se connecter
          </Button>
        </>
      ) : null}
    </>
  )
}
