import React from "react"
import { Alert } from "react-bootstrap"

export const PriceComparator = ({ children }) => {
  return (
    <Alert variant="info" style={{ marginTop: "15px" }}>
      <h3>
        Envoyez maintenant votre lettre en <strong>Recommandé avec AR</strong> pour <strong>4,68€</strong>
      </h3>
      {children}
    </Alert>
  )
}
export const TemplateBottomBanner = () => {
  return <></>
}
