import React, { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle"

export const AutoCompleteCityForm = ({ addressForm, updateAddressForm, errors, sectionAutoComplete }) => {
  const [isSearching, setIsSearching] = useState(false)
  const [options, updateOptions] = useState([])
  const [searchTerm, updateSearchTerm] = useState(addressForm.city)
  const [selected, updateSelected] = useState(addressForm.city ? [addressForm.city] : [])
  const [hasFocus, updateHasFocus] = useState(false)

  useEffect(() => {
    if (!hasFocus) {
      if (addressForm.city) {
        updateSelected([addressForm.city])
      } else {
        updateSelected([])
      }
    }
  }, [hasFocus, addressForm.city])

  const onSearch = async (value) => {
    updateAddressForm({ ...addressForm, city: value })
    updateSearchTerm(value)
  }

  const onChange = async (evt) => {
    if (evt.length === 1) {
      const properties = evt[0].properties
      updateAddressForm({ ...addressForm, zipCode: properties.postcode, city: properties.city })
      updateSelected([properties.city])
    } else {
      updateSelected([])
    }
  }

  const onInputChange = (anInput) => {
    onSearch(anInput)
  }

  function renderTooltip(props) {
    return (
      <Tooltip {...props}>
        Nos services sont disponibles pour la France Métropolitaine ou les DROM (Départements ou Région d'Outre-Mer)
      </Tooltip>
    )
  }

  return (
    <Form.Group controlId="cityId" data-testid="city">
      <Form.Label>
        VILLE
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
          <FontAwesomeIcon style={{ marginLeft: "8px" }} icon={faInfoCircle} />
        </OverlayTrigger>
      </Form.Label>
      <AsyncTypeahead
        isLoading={isSearching}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        onSearch={onSearch}
        placeholder={"Ville"}
        emptyLabel={"Aucune ville correspondante trouvée"}
        id="city"
        name="city"
        labelKey="label"
        promptText={"Nom de commune en France"}
        searchText="Recherche en cours"
        isInvalid={errors.city}
        selected={selected}
        onBlur={() => updateHasFocus(false)}
        onFocus={() => updateHasFocus(true)}
      />
      {/* Hidden form control so that the form control feedback works as expected */}
      <Form.Control name="city" style={{ display: "none" }} isInvalid={errors.city} />
      <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const AutoCompleteZipCodeForm = ({ addressForm, updateAddressForm, errors, sectionAutoComplete }) => {
  const [isSearching, setIsSearching] = useState(false)
  const [options, updateOptions] = useState([])
  const [searchTerm, updateSearchTerm] = useState(addressForm.zipCode)
  const [selected, updateSelected] = useState(addressForm.zipCode ? [addressForm.zipCode] : [])
  const [hasFocus, updateHasFocus] = useState(false)

  useEffect(() => {
    if (!hasFocus) {
      if (addressForm.zipCode) {
        updateSelected([addressForm.zipCode])
      } else {
        updateSelected([])
      }
    }
  }, [hasFocus, addressForm.zipCode])

  const onSearch = async (value) => {
    updateAddressForm({
      ...addressForm,
      zipCode: value,
    })
    updateSearchTerm(value)
  }

  const onInputChange = (anInput) => {
    onSearch(anInput)
  }

  const onChange = async (evt) => {
    if (evt.length === 1) {
      const properties = evt[0].properties
      updateAddressForm({ ...addressForm, zipCode: properties.postcode, city: properties.city })
      updateSelected([properties.postcode])
    } else {
      updateSelected([])
    }
  }

  return (
    <Form.Group controlId="codePostalId" data-testid="codePostal">
      <Form.Label>CODE POSTAL</Form.Label>
      <AsyncTypeahead
        isLoading={isSearching}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        onSearch={onSearch}
        placeholder={"Code postal"}
        emptyLabel={"Aucune ville correspondante trouvée"}
        id="zipCode"
        name="zipCode"
        labelKey="label"
        promptText="5 chiffres sans espace"
        searchText="Recherche en cours"
        isInvalid={errors.zipCode}
        selected={selected}
        onBlur={() => updateHasFocus(false)}
        onFocus={() => updateHasFocus(true)}
      />
      {/* Hidden form control so that the form control feedback works as expected */}
      <Form.Control name="zipCode" style={{ display: "none" }} isInvalid={errors.zipCode} />
      <Form.Control.Feedback type="invalid">{errors.zipCode}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const AutoCompleteAddressForm = ({ addressForm, updateAddressForm, errors, sectionAutoComplete }) => {
  const [isSearching, setIsSearching] = useState(false)
  const [options, updateOptions] = useState([])
  const [searchTerm, updateSearchTerm] = useState(addressForm.address)
  const [selected, updateSelected] = useState(addressForm.address ? [addressForm.address] : [])
  const [hasFocus, updateHasFocus] = useState(false)

  useEffect(() => {
    let isCancelled = false
    const searchAsync = async () => {
      setIsSearching(true)
      const response = await fetch(
        "https://api-adresse.data.gouv.fr/search/?q=" + encodeURI(searchTerm) + "&limit=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      if (!isCancelled) {
        const responseJson = await response.json()
        const features = responseJson.features
        const newOptions = features.map((f) => {
          const label = `${f.properties.label}`
          return { label, properties: f.properties }
        })
        if (!isCancelled) {
          updateOptions(newOptions)
          setIsSearching(false)
        }
      }
    }
    searchAsync()
      .then()
      .catch((e) => console.error(e))
    return () => {
      isCancelled = true
    }
  }, [searchTerm])

  useEffect(() => {
    if (!hasFocus) {
      if (addressForm.address) {
        updateSelected([addressForm.address])
      } else {
        updateSelected([])
      }
    }
  }, [hasFocus, addressForm.address])

  const onSearch = async (value) => {
    updateAddressForm({ ...addressForm, address: value })
    updateSearchTerm(value)
  }

  const onChange = async (evt) => {
    if (evt.length === 1) {
      const properties = evt[0].properties
      updateAddressForm({
        ...addressForm,
        address: properties.name,
        zipCode: properties.postcode,
        city: properties.city,
      })
      updateSelected([properties.name])
    } else {
      updateSelected([])
    }
  }

  return (
    <Form.Group controlId="addressPostalId" data-testid="addressPostal">
      <Form.Label>ADRESSE</Form.Label>
      <AsyncTypeahead
        isLoading={isSearching}
        onChange={onChange}
        options={options}
        onSearch={onSearch}
        placeholder={"Adresse"}
        emptyLabel={"Aucune adresse correspondante trouvée"}
        id="address"
        name="address"
        labelKey="label"
        promptText="Entrez une adresse pour lancer la recherche"
        isInvalid={errors.address}
        searchText="Recherche en cours"
        filterBy={() => true}
        selected={selected}
        onBlur={() => updateHasFocus(false)}
        onFocus={() => updateHasFocus(true)}
      />
      {/* Hidden form control so that the form control feedback works as expected */}
      <Form.Control name="zipCode" style={{ display: "none" }} isInvalid={errors.address} />
      <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
    </Form.Group>
  )
}
