import React, { useContext, useEffect, useMemo } from "react"
import { AccountContext } from "./AccountComponent"
import { LoginContext } from "../LoginModal"
import { Container, Jumbotron } from "react-bootstrap"
import { useLocation } from "react-router-dom"

export const LoginWall = ({ children }) => {
  const { isLoadingAccount, account } = useContext(AccountContext)
  const { pathname } = useLocation()
  const shouldShowLoginContent = useMemo(() => isLoadingAccount || account, [account, isLoadingAccount])
  const { showModal, visible } = useContext(LoginContext)
  useEffect(() => {
    if (!shouldShowLoginContent && !visible) {
      showModal(pathname, "/")
    }
  }, [shouldShowLoginContent, pathname, showModal, visible])
  return (
    <>
      {shouldShowLoginContent ? (
        <>{children}</>
      ) : (
        <>
          <Container>
            <Jumbotron>
              <h5>Vous devez etre connecte pour avoir acces a cet page</h5>
            </Jumbotron>
          </Container>
        </>
      )}
    </>
  )
}
