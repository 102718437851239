import React, { useContext, useEffect, useMemo, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { createAddressee, deleteAddressee, updateAddressee } from "../graphql/mutations/AddressMutations"
import { getAddressee } from "../graphql/queries"
import { onCreateAddressee, onDeleteAddressee, onUpdateAddressee } from "../graphql/subs/ContactSubscriptions"
import { AccountContext } from "../components/AccountComponent"
import { getAccountWithoutLetters } from "../customQueries"

export const ContactContext = React.createContext()
/**
 * @return {null}
 */
export const AddresseeProvider = (props) => {
  const { account } = useContext(AccountContext)
  const accountId = useMemo(() => account && account.id, [account])
  const [pages, setPages] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [count, setCount] = useState(0)

  const hasNextPage = pages.length > pageIndex && pages[pageIndex].nextToken
  const currentContacts = pages.length > pageIndex ? pages[pageIndex].items : []

  const countContacts = async () => {
    //This will not work if a user has more than 999 contacts
    //Dynamodb does not support count as of 12/2019
    //I'm sorry
    if (account) {
      const accountId = account.id
      const result = await API.graphql(
        graphqlOperation(getAccountWithoutLetters, { id: accountId, contactsLimit: 999 })
      )
      const newCount = result.data.getAccount.contacts.items.length
      setCount(newCount)
    }
  }

  const fetchNextPageContacts = async (accountId, nextPageToken) => {
    const result = await API.graphql(
      graphqlOperation(getAccountWithoutLetters, { id: accountId, contactsNextToken: nextPageToken, contactsLimit: 5 })
    )
    const account = result.data.getAccount
    return account.contacts
  }

  const browseNextPage = async () => {
    if (pages.length > pageIndex + 1) {
      setPageIndex(pageIndex + 1)
    } else {
      const nextPageToken = pages.length >= pageIndex ? pages[pageIndex].nextToken : null
      if (nextPageToken) {
        pages[pageIndex + 1] = await fetchNextPageContacts(account.id, nextPageToken)
        setPages(pages)
        setPageIndex(pageIndex + 1)
      }
    }
  }

  const browsePreviousPage = async () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  }

  const refreshLastContacts = async () => {
    if (account) {
      await countContacts()
      const firstPage = await fetchNextPageContacts(account.id, null)
      firstPage.items.forEach((cntct) => {
        if (cntct.isProfessional) {
          cntct.label = `${cntct.companyName} - ${cntct.firstName} ${cntct.lastName}`
        } else {
          cntct.label = `${cntct.firstName} ${cntct.lastName}`
        }
      })
      setPages([firstPage])
      setPageIndex(0)
    }
  }

  useEffect(() => {
    if (accountId) {
      refreshLastContacts()
        .then()
        .catch((e) => {
          console.log(`error during getAddresses : ${e}`)
        })
      countContacts()
        .then()
        .catch((e) => {
          console.log(`error during countContacts : ${e}`)
        })
    }
  }, [accountId])

  useEffect(() => {
    if (accountId) {
      //subscribe to letters
      // Subscribe to creation of Todo
      const subscriptionCreateLetter = API.graphql(graphqlOperation(onCreateAddressee, { owner: accountId })).subscribe(
        {
          next: (createData) => {
            console.log("createData")
          },
          error: (errorValue) => {
            console.log(errorValue)
          },
          complete() {},
        }
      )
      const subscriptionUpdateLetter = API.graphql(graphqlOperation(onUpdateAddressee, { owner: accountId })).subscribe(
        {
          next: (updateData) => {
            console.log("updateData")
          },
          error: (errorValue) => {
            console.log(errorValue)
          },
          complete() {},
        }
      )
      const subscriptionDeleteLetter = API.graphql(graphqlOperation(onDeleteAddressee, { owner: accountId })).subscribe(
        {
          next: (createData) => {
            console.log("deleteData")
          },
          error: (errorValue) => {
            console.log(errorValue)
          },
          complete() {},
        }
      )
      return () => {
        //We unsubscribe
        subscriptionCreateLetter.unsubscribe()
        subscriptionUpdateLetter.unsubscribe()
        subscriptionDeleteLetter.unsubscribe()
      }
    }
  }, [accountId])

  const createAddress = async (formData) => {
    const inputData = { addresseeAccountId: account.id, ...formData }
    const createAddresseeResult = await API.graphql(graphqlOperation(createAddressee, { input: inputData }))
    await refreshLastContacts()
    return createAddresseeResult.data.createAddressee
  }

  const editAddress = async (formData) => {
    //We prepare the formData and remove fields that should not be included in the update
    formData.owner = undefined
    formData.version = undefined
    formData.label = undefined
    formData.createdAt = undefined
    formData.updatedAt = undefined
    formData.account = undefined
    const lastVersionContactResult = await API.graphql(graphqlOperation(getAddressee, { id: formData.id }))
    const version = lastVersionContactResult.data.getAddressee.version
    formData.expectedVersion = version
    await API.graphql(graphqlOperation(updateAddressee, { input: formData }))
    await refreshLastContacts()
  }

  const removeContact = async (id) => {
    const formData = { id }
    await API.graphql(graphqlOperation(deleteAddressee, { input: formData }))
    await refreshLastContacts()
  }

  return (
    <ContactContext.Provider
      value={{
        count: count,
        editAddress: editAddress,
        createAddress: createAddress,
        removeContact: removeContact,
        browseNextPage: browseNextPage,
        browsePreviousPage: browsePreviousPage,
        hasNextPage: hasNextPage,
        pageIndex: pageIndex,
        currentContacts: currentContacts,
      }}
    >
      {props.children}
    </ContactContext.Provider>
  )
}
export const AddresseeConsumer = ContactContext.Consumer
