import API, { graphqlOperation } from "@aws-amplify/api"
import {
  getLetterPrice,
  listLettersSorted,
  getLetter as GetLetter,
  detectRecipient as DetectRecipient,
} from "../graphql/queries"
import { onCreateLetter, onDeleteLetter, onUpdateLetter } from "../graphql/subs/LetterSubscriptions"
import { createLetter as CreateLetter, updateLetter as UpdateLetter } from "../graphql/mutations/LetterMutations"
import { v4 as uuid } from "uuid"
import Storage from "@aws-amplify/storage"
import config from "../aws-exports"

//TODO have graphql return nextPageToken and items...
export const fetchNextPageLetters = async (nextToken, pageSize = 5) => {
  const result = await API.graphql(
    graphqlOperation(listLettersSorted, {
      filter: { status: { neq: "deleted" } },
      nextToken: nextToken,
      limit: pageSize,
    })
  )
  return result.data.listLettersSorted
}
export const apiDeleteLetter = async (letter) => {
  const inputData = { id: letter.id, status: "deleted", expectedVersion: letter.version }
  const result = await API.graphql(graphqlOperation(UpdateLetter, { input: inputData }))
  return result.data.updateLetter
}
export const apiCreateLetter = async (input) => {
  if (input.template) {
    input.template["__typename"] = undefined
    if (input.template.requiredDocuments) {
      input.template.requiredDocuments.forEach((rd) => (rd["__typename"] = undefined))
    }
  }
  const result = await API.graphql(graphqlOperation(CreateLetter, { input }))
  return result.data.createLetter
}
export const apiGetLetter = async (id) => {
  const result = await API.graphql(graphqlOperation(GetLetter, { id: id }))
  return result.data.getLetter
}
export const apiUpdateLetter = async (inputData) => {
  const result = await API.graphql(graphqlOperation(UpdateLetter, { input: inputData }))
  return result.data.updateLetter
}

export const subscribeCreateLetter = (accountId, callback) => {
  return API.graphql(graphqlOperation(onCreateLetter, { owner: accountId })).subscribe({
    next: (createData) => callback,
    error(errorValue) {
      console.log(errorValue)
    },
    complete() {},
  })
}
export const subscribeUpdateLetter = (accountId, callback) => {
  return API.graphql(graphqlOperation(onUpdateLetter, { owner: accountId })).subscribe({
    next: (updateData) => callback(),
    error(errorValue) {
      console.log(errorValue)
    },
    complete() {},
  })
}
export const subscribeDeleteLetter = (accountId, callback) => {
  return API.graphql(graphqlOperation(onDeleteLetter, { owner: accountId })).subscribe({
    next: (createData) => callback(),
    error(errorValue) {
      console.log(errorValue)
    },
    complete() {},
  })
}
export const getPrice = async (input) => {
  const apiResult = await API.graphql({ ...graphqlOperation(getLetterPrice, input), authMode: "API_KEY" })
  const price = JSON.parse(apiResult.data.getLetterPrice)
  return parseFloat(price.priceWithoutTax)
}
const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config
export const storeAttachments = async (filesWithPages, letterUid, username) => {
  const storeInputs = filesWithPages.map((fwp) => {
    const file = fwp.file
    const extension = file.name.split(".")[1]
    const { type: mimeType } = file
    const attachmentUid = uuid()
    const key = `${username}/${letterUid}/${attachmentUid}.${extension}`
    return { ...fwp, key, mimeType }
  })

  for (const storeInput of storeInputs) {
    const { file, key, mimeType } = storeInput
    await Storage.put(key, file, {
      level: "private",
      contentType: mimeType,
    })
  }
  return storeInputs.map((storeInput) => {
    const pages = storeInput.numPages
    const { file, key, mimeType } = storeInput
    return {
      format: mimeType,
      fileName: file.name,
      contentLength: file.size,
      pages: pages,
      content: {
        bucket,
        key,
        region,
      },
    }
  })
}

export const detectRecipient = async (document) => {
  const input = {
    bucket: document.bucket,
    key: document.key,
  }
  const apiResult = await API.graphql(graphqlOperation(DetectRecipient, input))
  return JSON.parse(apiResult.data.detectRecipient)
}
