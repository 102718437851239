import React, { useEffect, useState } from "react"
import Auth from "@aws-amplify/auth"
import { Hub } from "@aws-amplify/core"

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isLoadingUser, updateIsLoadingUser] = useState(false)

  useEffect(() => {
    const callback = (event) => {
      if (
        event.payload.event !== "signUp_failure" &&
        event.payload.event !== "forgotPassword_failure" &&
        event.payload.event !== "signUp" &&
        event.payload.event !== "forgotPassword"
      ) {
        loadUser().catch((e) => {
          console.log("error during user after hub event", e)
        })
      }
    }
    Hub.listen("auth", callback)
    loadUser(false).catch((e) => {
      console.error("error during user initialization", e)
    })
    return () => {
      Hub.remove("auth", callback)
    }
  }, [])

  const signinWith = async (email, password) => {
    await Auth.signIn(email, password)
  }

  const resendSignUp = async (email) => {
    await Auth.resendSignUp(email)
  }

  const registerWith = async (email, password) => {
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email, // optional
        // phone_number,   // optional - E.164 number convention
        // other custom attributes
      },
      validationData: [], //optional
    })
  }
  const continuePasswordFlow = async (email, code, password) => {
    await Auth.forgotPasswordSubmit(email, code, password)
  }

  const loadUser = async (bypassCache = false) => {
    updateIsLoadingUser(true)
    try {
      const userFromAuth = await Auth.currentAuthenticatedUser({ bypassCache })
      setUser(userFromAuth)
    } finally {
      updateIsLoadingUser(false)
    }
  }

  const logout = async () => {
    setUser(null)
    await Auth.signOut()
  }

  const resetPassword = async (email) => {
    await Auth.forgotPassword(email)
  }

  const updateEmail = async (email) => {
    await Auth.updateUserAttributes(user, { email })
    await loadUser(true)
  }

  const updatePassword = async (oldPassword, newPassword) => {
    await Auth.changePassword(user, oldPassword, newPassword)
    await loadUser(true)
  }

  return (
    <AuthContext.Provider
      value={{
        isLoadingUser,
        user,
        signinWith,
        registerWith,
        continuePasswordFlow,
        resetPassword,
        logout,
        updateEmail,
        updatePassword,
        resendSignUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer

export const withAuth = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <AuthConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</AuthConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}
