import React, { createContext } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { searchTemplateNavigation, findTemplateNavigation } from "../graphql/queries"

export const apiSearchTemplates = async (query, scope) => {
  const result = await API.graphql({
    ...graphqlOperation(searchTemplateNavigation, {
      query,
      scope,
    }),
    authMode: "API_KEY",
  })
  return JSON.parse(result.data.searchTemplateNavigation)
}
export const apiParseLocation = async (path) => {
  const result = await API.graphql({
    ...graphqlOperation(findTemplateNavigation, {
      path,
    }),
    authMode: "API_KEY",
  })
  const json = JSON.parse(result.data.findTemplateNavigation)
  return json
}

export const TemplateNavigationContext = createContext()

export const TemplateNavigationProvider = ({ children }) => {
  const openNavItem = (location, childNavItem) => {
    const newLocation = `/modele-lettre-recommandee/${childNavItem.slugs[0]}`
    return newLocation
  }

  const parseLocation = async (location) => {
    const path = location.pathname
    return await apiParseLocation(path)
  }

  return (
    <TemplateNavigationContext.Provider
      value={{
        parseLocation,
        openNavItem,
        searchTemplates: apiSearchTemplates,
      }}
    >
      {children}
    </TemplateNavigationContext.Provider>
  )
}
